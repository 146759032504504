import { v4 as uuidv4 } from "uuid";
function b1rmg() {
	return [
        {
            name: "Angels",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/Angels.mp3",
            color: ["#205950", "#2ab3bf"],
            id: uuidv4(),
            active: true,
        },
        {
            name: "Bad Behavior",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/BadBehavior.mp3",
            color: ["#EF8EA9", "#ab417f"],
            id: uuidv4(),
            active: false,
        },
        {
            name: "Crucial",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/Crucial.mp3",
            color: ["#CD607D", "#c94043"],
            id: uuidv4(),
            active: false,
        },
        {
            name: "Deepest Soul",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/DeepestSoul.mp3",
            color: ["#EF8EA9", "#ab417f"],
            id: uuidv4(),
            active: false,
        },
        {
            name: "Destroyed",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/Destroyed.mp3",
            color: ["#CD607D", "#c94043"],
            id: uuidv4(),
            active: false,
        },
        {
            name: "Fighting",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/Fighting.mp3",
            color: ["#205950", "#2ab3bf"],
            id: uuidv4(),
            active: false,
        },
        {
            name: "Fucked",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/Fucked.mp3",
            color: ["#205950", "#2ab3bf"],
            id: uuidv4(),
            active: false,
        },
        {
            name: "Revenge",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/Revenge.mp3",
            color: ["#205950", "#2ab3bf"],
            id: uuidv4(),
            active: false,
        },
        {
            name: "Trust Issue",
            cover: "https://mp3.b1rmg.com/logo.png",
            artist: "B-one Beats",
            audio: "https://mp3.b1rmg.com/tracks/TrustIssue.mp3",
            color: ["#205950", "#2ab3bf"],
            id: uuidv4(),
            active: false,
        },
	];
}

export default b1rmg;